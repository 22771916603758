/*! ctgenealogy.js */

function goToSearch() {
	$('#goToSearch').click(function(e) {
		// prevent default behavior
		e.preventDefault();
		// scroll to top of search form
		$('html,body').animate({scrollTop: $("#search").offset().top},1400);
	})
}

function goToTips() {
	$('#goToTips').click(function(e) {
		// prevent default behavior
		e.preventDefault();
		// scroll to top of the tips and tricks section, but 80 pixels higher up
		$('html,body').animate({scrollTop: $("#search-tips-and-tricks").offset().top - 80},1400);
	})
}

function goToErrors() {
	// scroll to the errors notifcation (this function gets called, so no click needed)
	$("#errors").removeClass('hide');
	$("#errors #error-date").removeClass('hide');
	$('html,body').animate({scrollTop: $("#search #errors").offset().top - 80},1400);
}

function resetSearch() {
	$('#btn-reset').click(function(e) {
		// prevent default behavior
		e.preventDefault();
		// clear search form
		$("#search form").trigger('reset');
		// extra handling for the datepicker
		$('#startDate').datepicker('setDate', null);
		$('#startDate').attr('data-formatted-date','');
		$('#endDate').datepicker('setDate', null);
		$('#endDate').attr('data-formatted-date','');
		// clear error messages
		$("#errors").addClass('hide');
		$("#errors #error-date").addClass('hide');
		// scroll to top of search form
		$('html,body').animate({scrollTop: $("#search").offset().top},1400);
	})
}

function datePickerLaunch() {
	$('#startDate').datepicker({
		date: new Date(1897, 0, 1), // initial date when first opened - 1/1/1897
		startDate: new Date(1897, 0, 1), // earliest possible date, all earlier ones disabled
		endDate: new Date(2017, 11, 31), // last possible date, all later ones disabled  - 12/31/2017
		startView: 2, // start with year view
		format: 'm/d/yyyy'
	});
	$('#endDate').datepicker({
		date: new Date(2017, 11, 31), // initial date when first opened - 12/31/2017
		startDate: new Date(1897, 0, 1), // earliest possible date, all earlier ones disabled  - 1/1/1897
		endDate: new Date(2017, 11, 31), // last possible date, all later ones disabled  - 12/31/2017
		startView: 2, // start with year view
		format: 'm/d/yyyy'
	});
	// when start date is chosen, set it to the earliest possible start date for the second datepicker, to make sure the start date is always before the end date
	$(document).on('change', '#startDate', (e) => {
		e.preventDefault();
		const startDate = $(e.target).val();
		$('#endDate').datepicker('setStartDate', startDate);
		$('#endDate').datepicker('setDate', startDate);
	});
	$(document).on('pick.datepicker', '#startDate', (e) => {
		let originalStartDate = new Date(e.date);
		let formattedStartDate = ( originalStartDate.getFullYear() + '-' + ((originalStartDate.getMonth()+1).toString().padStart(2,'0')) + '-' + (originalStartDate.getDate().toString().padStart(2,'0')) + 'T00:00:00Z');
		// write the formatted date to value of the input so we can grab it when we build our search
		$('#startDate').attr('data-formatted-date', formattedStartDate);
	});
	$(document).on('pick.datepicker', '#endDate', (e) => {
		let originalEndDate = new Date(e.date);
		let formattedEndDate = ( originalEndDate.getFullYear() + '-' + ((originalEndDate.getMonth()+1).toString().padStart(2,'0')) + '-' + (originalEndDate.getDate().toString().padStart(2,'0')) + 'T00:00:00Z');
		// write the formatted date to value of the input so we can grab it when we build our search
		$('#endDate').attr('data-formatted-date', formattedEndDate);
	});
}


function loadTownNames() {
	var urlForAllTowns = '/solrsearch/?q=*:*&facet=true&facet.field=record_location_town&rows=0&facet.limit=100000';
	// urlForAllTowns += '&wt=json&json.wrf=?';
	$.ajax({
		type: 'GET',
		url: urlForAllTowns,
		dataType: 'json', // text or json or jsonp or script
		// timeout : 10000,
		success: function(data) {
			// get the data
	        var dataTownNames = data.facet_counts.facet_fields.record_location_town;
	        if ( dataTownNames && (dataTownNames !== '') && (dataTownNames !== null) ) {
		        // it comes out of Solr with town names and counts, separated by commas, so we should grab the town names only, and then sentence-case them
				var dataTownNamesCleaned = [];
				var dataTownNamesCleaned2 = [];
				var dataTownNamesCleanedSentenceCase = [];
				dataTownNames.toString().replace(/([^\,]+)\,([^\,]+)/g, function($0, $1, $2){
					// create key:value pairs from the comma-separated stuff
					dataTownNamesCleaned[$1] = $2;
					// now just get the keys
					dataTownNamesCleaned2 = Object.keys(dataTownNamesCleaned);
				});
				// console.log(dataTownNamesCleaned2);
				// now filter the resulting array to remove a few things
				dataTownNamesCleaned2 = dataTownNamesCleaned2.filter((key) => {return key != '1'});
				dataTownNamesCleaned2 = dataTownNamesCleaned2.filter((key) => {return key != '150'});
				dataTownNamesCleaned2 = dataTownNamesCleaned2.filter((key) => {return key != '.'});
				dataTownNamesCleaned2 = dataTownNamesCleaned2.filter((key) => {return key != '[missing or unknown]'});
				dataTownNamesCleaned2 = dataTownNamesCleaned2.filter((key) => {return key != 'Outside of Connectic'});
				dataTownNamesCleaned2 = dataTownNamesCleaned2.filter((key) => {return key != 'UNKNOWN CT TOWN'});
				dataTownNamesCleaned2 = dataTownNamesCleaned2.filter((key) => {return key != 'UNKNOWN'});
				// now sentence case it
				dataTownNamesCleanedSentenceCase = dataTownNamesCleaned2.map(townName => townName.split(' ').map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase()).join(' '));
				// now alphabetize the array
				dataTownNamesCleanedSentenceCase = dataTownNamesCleanedSentenceCase.sort();
				// console.log(dataTownNamesCleanedSentenceCase);
				// now add this array as a select with options for the town search
				var townSelect = document.getElementById("geosearch-town");
				if (townSelect && (townSelect !== '') && (townSelect !== null) ) {
					for (var i = 0; i < dataTownNamesCleanedSentenceCase.length; i++) {
						var option = document.createElement("option");
						option.value = dataTownNamesCleanedSentenceCase[i].toUpperCase();
						option.text = dataTownNamesCleanedSentenceCase[i];
						townSelect.appendChild(option);
					}
				} else {
					// if we can't append the data to the select, then as a last resort just hide the town stuff altogether
					console.log('Error: cannot append town data to the select');
					$("#search-places-town").css("display", "none");
				}
			} else {
				// if we can't get the data from the server, then as a last resort just hide the town stuff altogether
				console.log('Error: cannot parse town data from the server');
				$("#search-places-town").css("display", "none");
			}
		},
		error: function (data) {
			// if we can't get the data from the server, then as a last resort just hide the town stuff altogether
			console.log('Error: cannot get town data from the server');
			$("#search-places-town").css("display", "none");
		}
	});
}

function buildSearch() {
	$('#btn-search').click(function(e) {
		e.preventDefault();

		var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
		var monthNamesShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

		// clear any potential existing variables and/or results from a previous search
		var givenname = '';
		var surname = '';
		var givennameSearchType = '';
		var surnameSearchType = '';
		var dateStart = '';
		var dateEnd = '';
		var geosearchDistance = '';
		var geosearchStartingLatLon = '';
		var geosearchStartingName = '';
		var geosearchCounty = '';
		var geosearchTown = '';

		// hide parts of the site
		$("#noResults").addClass('hide');
		$("#containerGraph").addClass('hide');
		$("#containerMap").addClass('hide');
		$("#resultsTabs").addClass('hide');
		$("#resultsContent").addClass('hide');
		$("#results table").addClass('hide');
		$("#resultsHereBirths").empty();
		$("#resultsHereMarriages").empty();
		$("#resultsHereDeaths").empty();

		// get new/current form values
		var givenname = $("#search-givenname").val();
		var surname = $("#search-surname").val();
		var givennameSearchType = $("input[name='givenname-search-type']:checked").val();
		var surnameSearchType = $("input[name='surname-search-type']:checked").val();
		var dateStart = $('#startDate').attr('data-formatted-date');
		var dateEnd = $('#endDate').attr('data-formatted-date');
		var geosearchDistance = $("#geosearch-distance").val();
		var geosearchStartingLatLon = $("#geosearch-startinglatlon").val();
		var geosearchStartingName = $("#geosearch-startinglatlon option:selected").text();
		var geosearchCounty = $("#geosearch-county").val();
		var geosearchTown = $("#geosearch-town").val();

		// remove all characters that are not a letter, comma, hyphen, apostrophe, period, asterisk, or space
		givenname = givenname.replace(/[^A-Za-z,\-'.*\s]/g, '');
		surname = surname.replace(/[^A-Za-z,\-'.*\s]/g, '');

		// make sure it's all lowercase
		givenname = givenname.toLowerCase();
		surname = surname.toLowerCase();

		// if the given name *and* the surname were both left blank, set the surname as an asterisk to make the results work
		if ( ((givenname === '') || (givenname === null)) && ((surname === '') || (surname === null)) ) {
			surname = '*';
		}

		// start to build the solr query
		var thequery = '/solrsearch/';
		thequery += '?q=';

		// first add the must-have filters, like dates and county names and town names
		var isThereADate = '';
		var isThereACounty = '';
		var isThereATown = '';

		// add the date range, if it exists
		if ( dateStart && (dateStart !== '') && (typeof dateStart !== 'undefined') && dateEnd && (dateEnd !== '') && (typeof dateEnd !== 'undefined') ) {
			thequery += 'record_date_formatted:[' + dateStart + '%20TO%20' + dateEnd + ']';
			var isThereADate = 'yes';
		}

		// add the county, if it was selected
		if ( geosearchCounty && (geosearchCounty !== '') ) {
			if (isThereADate && (isThereADate == 'yes')) {
				thequery += '%20AND%20';
			}
			// note that this needs html-encoded quotation marks, since some county names have a space in them
			thequery += 'record_location_county_name:' + '%22' + geosearchCounty + '%22';
			var isThereACounty = 'yes';
		}

		// add the town, if it was selected
		if ( geosearchTown && (geosearchTown !== '') ) {
			if ( (isThereADate && (isThereADate == 'yes')) || (isThereACounty && (isThereACounty == 'yes')) ) {
				thequery += '%20AND%20';
			}
			// note that this needs html-encoded quotation marks, since some town names have a space in them
			thequery += 'record_location_town:' + '%22' + geosearchTown + '%22';
			var isThereATown = 'yes';
		}

		if ( ((isThereADate === 'yes') || (isThereACounty === 'yes') || (isThereATown === 'yes')) && ( ((surname !== '') && (surname !== '*')) || (givenname !== '') )) {
			thequery += '%20AND%20';
			thequery += '(';
		}

		// start the main stuff to be searched - these values will be separated by a space which will be interpreted as an "OR"

		// search on surname
		if ( (surname !== '') && (surname !== '*') ) {
			// if there is a space in a surname, like "de simone", search with quotes around the surname *and* also search on a version that has all the spaces removed
			if (surname.toString().indexOf(' ') > -1) { 
				var surnameNoSpaces = surname.replace(/\s/g, '');
				var surnameWithQuotesAndWithoutSpaces = '("' + surname + '"%20OR%20'+ surnameNoSpaces + ')';
				thequery += 'record_surnames:' + surnameWithQuotesAndWithoutSpaces + '%20'; // no tilde
				if (surnameSearchType == 'soundalike') {
					// search on BMPM version, no tilde
					thequery += 'record_surnames_bmpm:' + surnameWithQuotesAndWithoutSpaces + '%20'; // no tilde
				}
			} else {
				// no spaces in the surname
				if (surname.indexOf('*') == '-1') {
					// there is NOT an asterisk in the search term
					if (surnameSearchType == 'soundalike') {
						thequery += 'record_surnames:' + surname + '%20'; // no tilde
						thequery += 'record_surnames:' + surname + '~%20'; // adds tilde for Levenshtein distance
						thequery += 'record_surnames_bmpm:' + surname + '%20'; // no tilde
					} else if (surnameSearchType == 'exact') {
						thequery += 'record_surnames:' + surname + '%20'; //no tilde
					}
				} else {
					// there IS an asterisk in the search term, meaning a wildcard
					thequery += 'record_surnames:' + surname + '%20'; // no tilde
				}
			}
		} else if (surname === '*')  {
			thequery += 'record_surnames:' + surname + '%20'; // no tilde
		}

		// search on givenname
		if (givenname !== '') {
			// if there is a space in a given name, like "mary jane", search with quotes around the given name *and* also search on a version that has all the spaces removed
			if (givenname.toString().indexOf(' ') > -1) { 
				console.log('There is a space in the given name');
				var givennameNoSpaces = givenname.replace(/\s/g, '');
				var givennameWithQuotesAndWithoutSpaces = '("' + givenname + '"%20OR%20'+ givennameNoSpaces + ')';
				thequery += 'record_givennames:' + givennameWithQuotesAndWithoutSpaces + '%20'; // no tilde
				if (givennameNoSpaces.length < 6) {
					thequery += 'record_givennames:' + givennameNoSpaces + '*%20'; // adds wildcard to very short names
				}
				if (givennameSearchType == 'soundalike') {
					// search on BMPM version, no tilde
					thequery += 'record_givennames_bmpm:' + givennameWithQuotesAndWithoutSpaces + '%20'; // no tilde
				}
			} else {
				// no spaces in the givenname
				if (givenname.indexOf('*') == '-1') {
					// there is NOT an asterisk in the search term
					if (givennameSearchType == 'soundalike') {
						thequery += 'record_givennames:' + givenname + '%20'; // no tilde
						thequery += 'record_givennames:' + givenname + '~%20'; // adds tilde for Levenshtein distance
						if (givenname.length < 6) {
							thequery += 'record_givennames:' + givenname + '*%20'; // adds wildcard to very short names
						}
						thequery += 'record_givennames_synonyms:' + givenname + '%20'; // no tilde
						thequery += 'record_givennames_bmpm:' + givenname + '%20'; // no tilde
					} else if (givennameSearchType == 'exact') {
						thequery += 'record_givennames:' + givenname + '%20'; //no tilde
					}
					// make sure to also search on those years where there are only five-letter given names in the dataset
					var givennameFirstFive = givenname.substring(0,5);
					thequery += 'record_givennames:' + givennameFirstFive; // no tilde and no space
				} else {
					// there IS an asterisk in the search term, meaning a wildcard
					thequery += 'record_givennames:' + givenname + '%20'; // no tilde
				}
			}
		}

		// add town, if any selected - make sure this is a SOUNDALIKE search and not a hard filter like how the county is, because some of the towns are misspelled in the original databases.
		// Possible to-do: add html-encoded QUOTATION MARKS because many town names have spaces
		if ( geosearchTown && (geosearchTown !== '') ) {
			if (geosearchTown.toString().indexOf(' ') > -1) { 
				// there is a space in the town name
				var geosearchTownNoSpaces = geosearchTown.replace(/\s/g, '');
				thequery += '%20' + 'record_location_town:' + geosearchTownNoSpaces + '~%20'; // adds tilde for Levenshtein distance
			} else {
				// no space in the town name
				thequery += '%20' + 'record_location_town:' + geosearchTown + '~%20'; // adds tilde for Levenshtein distance
			}
		}

		if ( ((isThereADate === 'yes') || (isThereACounty === 'yes') || (isThereATown === 'yes')) && ( ((surname !== '') && (surname !== '*')) || (givenname !== '') )) {
			thequery += ')';
		}

		// now add the boost queries, only to boost any results that exactly match *both* given name and surname
		if ( (givenname !== '') && (surname !== '') && (surname !== '*') ) {
			thequery += '&bq=';
			thequery += '(record_givennames:' + givenname + '%20AND%20record_surnames:' + surname + ')^10';
		}

		// now add the query fields, to say which fields are generally more important than others
		thequery += '&qf=';
		if ( (surname !== '') && (surname !== '*') ) {
			thequery += 'record_surnames^25' + '%20';
			thequery += 'record_surnames_bmpm^5'; // no space
		}
		if (givenname !== '') {
			if ( (surname !== '') && (surname !== '*') ) {
				thequery += '%20';
			}
			if (givenname.indexOf('*') == '-1') {
				// there is NOT an asterisk in the search term
				thequery += 'record_givennames^5' + '%20';
				thequery += 'record_givennames_synonyms^15' + '%20';
				thequery += 'record_givennames_bmpm^2'; // no space
			} else {
				// there IS an asterisk in the search term, meaning a wildcard
				thequery += 'record_givennames^5';  // no space
			}
		}
		if (geosearchTown !== '') {
			thequery += '%20';
			thequery += 'record_location_town^2';  // no space
		}

		// add filter queries (fq) to limit the results if the search is exact-only
		if ( (surname !== '') && (surname !=='*') && (surnameSearchType == 'exact') ) {
			// if there is a space in a surname, like "de simone", search with quotes around the surname but *do not* also search on a version that has all the spaces removed, because this is looking for exact
			if (surname.toString().indexOf(' ') > -1) {
				thequery += '&fq=record_surnames:' + '("' + surname + '")';
			} else {
				thequery += '&fq=record_surnames:' + surname;
			}
		}
		if ( (givenname !== '') && (givennameSearchType == 'exact') ) {
			// if there is a space in a given name, like "mary jane", search with quotes around the given name but *do not* also search on a version that has all the spaces removed, because this is looking for exact
			if (givenname.toString().indexOf(' ') > -1) {
				thequery += '&fq=record_givennames:' + '("' + givenname + '")';
			} else {
				thequery += '&fq=record_givennames:' + givenname;
				if (givennameFirstFive && (givenname !== givennameFirstFive)) {
					thequery += '%20OR%20' + givennameFirstFive;
				}
			}
		}

		// add geosearch, if it exists
		if ( geosearchDistance && (geosearchDistance !== '') && geosearchStartingLatLon && (geosearchStartingLatLon !== '') ) {
			thequery += '&sfield=record_location_latlon_rpt&pt=' + geosearchStartingLatLon + '&d=' + geosearchDistance + '&fq={!geofilt%20cache=false%20cost=100}';
		}

		// add result grouping, to get the top 100 results for each record type (births, marriage, etc.) - but this will force it to be 100 per type, even if there are few or no good matches in that type - use this only if there is no given name and no surname listed, just a date or county or town
		// if ( ((isThereADate === 'yes') || (isThereACounty === 'yes') || (isThereATown === 'yes')) && ( ((surname === '') || (surname === '*')) && (givenname === '') )) {
		if (surname === '*') {
			var useTopRecordsPerRecordType = 'true';
			thequery += '&group=true&group.format=simple&group.field=record_type&group.limit=100';
		}

		// attempt to limit results to only highly scoring results, not just the top 1000 - this should get score is greater than 25 (l means lower bound)
		// thequery += '&fq:{!frange l=25}query($q)';

		// add special facet stuff just for the chart
		if ( isThereADate && (isThereADate == 'yes') ) {
			var yearsBetweenDates = new Date(new Date(dateEnd) - new Date(dateStart)).getFullYear() - 1970;
			var daysBetweenDates = ( (new Date(dateEnd).getTime()) - (new Date(dateStart).getTime()) ) / (1000 * 3600 * 24);
			if ( (yearsBetweenDates == 0) && (daysBetweenDates <= 140) ) {
				var thequeryForChart = thequery + '&rows=0&facet=true&facet.pivot={!range=r1}record_type&facet.sort=record_type&facet.range={!tag=r1}record_date_formatted&facet.range.start=' + dateStart + '&facet.range.end=' + dateEnd + '&facet.range.gap=%2B1DAY&wt=json&json.wrf=?';
			} else if (yearsBetweenDates <= 12) {
				var thequeryForChart = thequery + '&rows=0&facet=true&facet.pivot={!range=r1}record_type&facet.sort=record_type&facet.range={!tag=r1}record_date_formatted&facet.range.start=' + dateStart + '&facet.range.end=' + dateEnd + '&facet.range.gap=%2B1MONTH&wt=json&json.wrf=?';
			} else {
				var thequeryForChart = thequery + '&rows=0&facet=true&facet.pivot={!range=r1}record_type&facet.sort=record_type&facet.range={!tag=r1}record_date_formatted&facet.range.start=' + dateStart + '&facet.range.end=' + dateEnd + '&facet.range.gap=%2B1YEAR&wt=json&json.wrf=?';
			}
		} else {
			// no date range was specified in the search, so show five-year intervals, not one-year
			var thequeryForChart = thequery + '&rows=0&facet=true&facet.pivot={!range=r1}record_type&facet.sort=record_type&facet.range={!tag=r1}record_date_formatted&facet.range.start=1897-01-01T00:00:00Z&facet.range.end=2018-12-31T00:00:00Z&facet.range.gap=%2B5YEAR&wt=json&json.wrf=?';
		}

		// add facet stuff for the map
		thequery += '&facet=true&facet.pivot.mincount=1&facet.pivot=record_location_town,record_location_latlon_text';

		// figure out stuff to put in the subtitle of the chart and the map
		var subtitleStuff = '';
		if ( (surname !== '') && (surname !== '*') && (surnameSearchType == 'exact') && (givenname !== '') && (givennameSearchType == 'exact') ) {
			subtitleStuff += 'Exact matches for ';
		} else {
			subtitleStuff += 'Close matches for ';
		}
		if (givenname !== '') {
			subtitleStuff += givenname.toUpperCase();
		} else {
			subtitleStuff += '[no given name] ';
		}
		if ( (givenname !== '') && (surname !== '') && (surname !== '*') ) {
			subtitleStuff += ' ';
		}
		if ( (surname !== '') && (surname !== '*') ) {
			subtitleStuff += surname.toUpperCase();
		} else {
			subtitleStuff += ' [no surname] ';
		}
		if ( geosearchTown && (geosearchTown !== '') ) {
			subtitleStuff += ' in ' + geosearchTown;
		}
		if (isThereACounty === 'yes') {
			subtitleStuff += ' in ' + geosearchCounty + ' COUNTY';
		}
		if ( geosearchDistance && (geosearchDistance !== '') && geosearchStartingLatLon && (geosearchStartingLatLon !== '') && geosearchStartingName && (geosearchStartingName !== '') ) {
			subtitleStuff += ' within ' + geosearchDistance + ' miles of ' + geosearchStartingName;
		}
		if (isThereADate === 'yes') {
			// make short dates from iso dates, and remember to account for timezone or else day will be off by one
			var dateStartShort = new Date(dateStart).toLocaleString("en-US", {timeZone: "UTC", year: 'numeric', month: '2-digit', day: '2-digit'});
			var dateEndShort = new Date(dateEnd).toLocaleString("en-US", {timeZone: "UTC", year: 'numeric', month: '2-digit', day: '2-digit'});
			subtitleStuff += ' between ' + dateStartShort + ' and ' + dateEndShort;
		}
		
		// add rows and where to start (this will potentially be for pagination)
		thequery += '&rows=1000&start=0';

		// the finale!
		thequery += '&defType=edismax&indent=on&fl=*,score&wt=json&json.wrf=?';

		// if we only have one date selected, show an error message and don't show results
		if ( (!dateStart || (dateStart == '') ) && (dateEnd && (dateEnd !== '')) ) {
			// console.log('Error: no starting date selected');
			goToErrors();
		} else if ( (dateStart && (dateStart !== '')) && (!dateEnd || (dateEnd == '')) ) {
			// console.log('Error: no ending date selected');
			goToErrors();

		} else {
			// bring. it. on.

			// hide some stuff
			$("#errors").addClass('hide');
			$("#noResults").addClass('hide');
			// unhide the search results section
			$("#results").removeClass('hide');
			// add spinner
			$("#results").addClass('loading');
			// clear any old content
			$("#totalBirths").html("");
			$("#totalMarriages").html("");
			$("#totalCivilUnions").html("");
			$("#totalDeaths").html("");
			$("#resultsHereBirths").html("");
			$("#resultsHereMarriages").html("");
			$("#resultsHereCivilUnions").html("");
			$("#resultsHereDeaths").html("");
			// scroll to search results
			$('html,body').animate({scrollTop: $("#results").offset().top + (-40)},1400);

			console.log('Searching: ' + thequery);
			console.log('Given name: ' + givenname);
			console.log('Type of given name search: ' + givennameSearchType);
			console.log('Surname: ' + surname);
			console.log('Type of surname search: ' + surnameSearchType);
			console.log('Date start: ' + dateStart);
			console.log('Date end: ' + dateEnd);
			console.log('GeoSearch Distance: ' + geosearchDistance);
			console.log('GeoSearch Starting LatLon: ' + geosearchStartingLatLon);
			console.log('GeoSearch Starting Name: ' + geosearchStartingName);
			console.log('GeoSearch County: ' + geosearchCounty);
			console.log('GeoSearch Town: ' + geosearchTown);

			// get the data
			$.getJSON(thequery, function(json){
				if ( useTopRecordsPerRecordType && (useTopRecordsPerRecordType === 'true') ) {
					// if we're doing grouped results, meaning top 100 per record type, then the resulting JSON is formatted a little differently
					var recordsRoot = json.grouped.record_type.doclist;
				} else {
					var recordsRoot = json.response;
				}
				// the score needs to be *at least* 1.0, because a search with no name equals 1.0
				if ( (recordsRoot.numFound == '0') || (recordsRoot.numFound === 0) || (recordsRoot.maxScore < '1.0') ) {
					// console.log('Number of Records Found: ' + recordsRoot.numFound);
					// $("#results").addClass('hide');
					$("#noResults").removeClass('hide');
					$("#containerGraph").addClass('hide');
					$("#containerMap").addClass('hide');
					$("#results p.subheading").addClass('hide');
					$("#resultsTabs").addClass('hide');
					$("#resultsContent").addClass('hide');
					$("#results table").addClass('hide');
				} else {
					// console.log('Number of Records Found: ' + recordsRoot.numFound);
					$("#results").removeClass('hide');
					$("#noResults").addClass('hide');
					$("#containerGraph").removeClass('hide');
					$("#containerMap").removeClass('hide');
					// send the special query for use by the chart
					doHighchartsGraph(thequeryForChart, subtitleStuff);
					// send the full query for reuse by the map
					doHighchartsMap(thequery, subtitleStuff);
					$("#results p.subheading").removeClass('hide');
					$("#resultsTabs").removeClass('hide');
					$("#resultsContent").removeClass('hide');
					$("#results table").removeClass('hide');
					var numberOfRelevantResultsBirths = 0;
					var numberOfRelevantResultsMarriages = 0;
					var numberOfRelevantResultsCivilUnions = 0;
					var numberOfRelevantResultsDeaths = 0;
					for (let i = 0; i < recordsRoot.docs.length; i++) {
						var record_type = recordsRoot.docs[i].record_type, 
						record_givenname = recordsRoot.docs[i].record_givenname, 
						record_surname = recordsRoot.docs[i].record_surname, 
						record_suffix = recordsRoot.docs[i].record_suffix,
						record_bride_givenname = recordsRoot.docs[i].record_bride_givenname, 
						record_bride_surname = recordsRoot.docs[i].record_bride_surname, 
						record_bride_suffix = recordsRoot.docs[i].record_bride_suffix, 
						record_groom_givenname = recordsRoot.docs[i].record_groom_givenname, 
						record_groom_surname = recordsRoot.docs[i].record_groom_surname, 
						record_groom_suffix = recordsRoot.docs[i].record_groom_suffix, 
						record_partner1_givenname = recordsRoot.docs[i].record_partner1_givenname, 
						record_partner1_surname = recordsRoot.docs[i].record_partner1_surname, 
						record_partner1_suffix = recordsRoot.docs[i].record_partner1_suffix, 
						record_partner2_givenname = recordsRoot.docs[i].record_partner2_givenname, 
						record_partner2_surname = recordsRoot.docs[i].record_partner2_surname, 
						record_partner2_suffix = recordsRoot.docs[i].record_partner2_suffix, 
						record_date_original = recordsRoot.docs[i].record_date_original,
						record_date_formatted = recordsRoot.docs[i].record_date_formatted, 
						record_location_town = recordsRoot.docs[i].record_location_town, 
						record_location_county = recordsRoot.docs[i].record_location_county_name, 
						record_location_latlon_point = recordsRoot.docs[i].record_location_latlon_point,
						record_state_file_number = recordsRoot.docs[i].record_state_file_number,
						record_id = recordsRoot.docs[i].record_id,
						score = recordsRoot.docs[i].score;

						// sort the four different record types into four separate tables
						if ( (record_type !== '') && (record_type !== null) && (record_type !== undefined) ) {

							// handle births
							if (record_type == 'BIRTH') {
								// if (score > '1') {
									numberOfRelevantResultsBirths = numberOfRelevantResultsBirths + 1;
									var template = '<tr';
									if ( (record_id !== '') && (record_id !== null) && (record_id !== undefined) ) { template += ' id="row-' + record_id + '">'; } else { template += '>'; };
									if ( (record_givenname !== '') && (record_givenname !== null) && (record_givenname !== undefined) ) { template += '<td data-th="Given Name">' + record_givenname.toUpperCase() + '</td>'; } else { template += '<td data-th="Given Name">-</td>'; };
									if ( (record_surname !== '') && (record_surname !== null) && (record_surname !== undefined) ) { template += '<td data-th="Surname">' + record_surname.toUpperCase() + '</td>'; } else { template += '<td data-th="Surname">-</td>'; };
									if ( (record_suffix !== '') && (record_suffix !== null) && (record_suffix !== undefined) ) { template += '<td data-th="Suffix">' + record_suffix.toUpperCase() + '</td>'; } else { template += '<td data-th="Suffix">-</td>'; };
									/* Use formatted date, not original date, as some are created weirdly */
									if ( (record_date_formatted !== '') && (record_date_formatted !== null) && (record_date_formatted !== undefined) ) { 
										var record_date_formatted_obj = '',
										record_date_month = '',
										record_date_day = '',
										record_date_year = '';
										/* need to format the date as a Date object first */ 
										var record_date_formatted_obj = new Date(record_date_formatted);
										// if ( (record_date_formatted_obj.isValid()) === true ) {
										if ( record_date_formatted_obj && (record_date_formatted_obj !== undefined) ) {
											var record_date_month = monthNamesShort[record_date_formatted_obj.getUTCMonth()].toUpperCase();
											var record_date_day = record_date_formatted_obj.getUTCDate();
											var record_date_year = record_date_formatted_obj.getUTCFullYear();
											// console.log('Original date: ' + record_date_formatted);
											// console.log('Formatted date: ' + record_date_formatted_obj);		
											template += '<td data-th="Date of Record">' + record_date_month + ' ' + record_date_day + ', ' + record_date_year + '</td>'; 
										} else {
											template += '<td data-th="Date of Record">(invalid date; originally listed as ' + record_date_formatted + ')</td>';
										}
									} else { 
										template += '<td data-th="Date of Record">-</td>';
									};
									if ( (record_location_town !== '') && (record_location_town !== null) && (record_location_town !== undefined) ) { template += '<td data-th="Town or City">' + record_location_town + '</td>'; } else { template += '<td data-th="Town or City">-</td>'; };
									if ( (record_location_county !== '') && (record_location_county !== null) && (record_location_county !== undefined) ) { template += '<td data-th="County">' + record_location_county + '</td>'; } else { template += '<td data-th="County">-</td>'; };
									// template += '<td data-th="Order Certificate"><button class="button is-small btn-align accent-btn raised rounded btn-outlined">Order Certificate</button></td>';
									template += '</tr>';
									$("#resultsHereBirths").append(template);
								// }
							}
							// handle marriages
							if (record_type == 'MARRIAGE') {
								// if (score > '1') {
									numberOfRelevantResultsMarriages = numberOfRelevantResultsMarriages + 1;
									var template = '<tr';
									if ( (record_id !== '') && (record_id !== null) && (record_id !== undefined) ) { template += ' id="row-' + record_id + '">'; } else { template += '>'; };
									if (record_bride_givenname || record_bride_surname || record_groom_givenname || record_groom_surname) {
										if ( (record_bride_givenname !== '') && (record_bride_givenname !== null) && (record_bride_givenname !== undefined) ) { template += '<td data-th="Bride (or Partner #1) Given Name">' + record_bride_givenname.toUpperCase() + '</td>'; } else { template += '<td data-th="Bride (or Partner #1) Given Name">-</td>'; };
										if ( (record_bride_surname !== '') && (record_bride_surname !== null) && (record_bride_surname !== undefined) ) { template += '<td data-th="Bride (or Partner #1) Surname">' + record_bride_surname.toUpperCase() + '</td>'; } else { template += '<td data-th="Bride (or Partner #1) Surname">-</td>'; };
										if ( (record_bride_suffix !== '') && (record_bride_suffix !== null) && (record_bride_suffix !== undefined) ) { template += '<td data-th="Bride (or Partner #1) Suffix">' + record_bride_suffix.toUpperCase() + '</td>'; } else { template += '<td data-th="Bride (or Partner #1) Suffix">-</td>'; };
										if ( (record_groom_givenname !== '') && (record_groom_givenname !== null) && (record_groom_givenname !== undefined) ) { template += '<td data-th="Groom (or Partner #2) Given Name">' + record_groom_givenname.toUpperCase() + '</td>'; } else { template += '<td data-th="Groom (or Partner #2)Given Name">-</td>'; };
										if ( (record_groom_surname !== '') && (record_groom_surname !== null) && (record_groom_surname !== undefined) ) { template += '<td data-th="Groom (or Partner #2) Surname">' + record_groom_surname.toUpperCase() + '</td>'; } else { template += '<td data-th="Groom (or Partner #2) Surname">-</td>'; };
										if ( (record_groom_suffix !== '') && (record_groom_suffix !== null) && (record_groom_suffix !== undefined) ) { template += '<td data-th="Groom (or Partner #2) Suffix">' + record_groom_suffix.toUpperCase() + '</td>'; } else { template += '<td data-th="Groom (or Partner #2) Suffix">-</td>'; };
									} else {
										if ( (record_partner1_givenname !== '') && (record_partner1_givenname !== null) && (record_partner1_givenname !== undefined) ) { template += '<td data-th="Partner #1 Given Name">' + record_partner1_givenname.toUpperCase() + '</td>'; } else { template += '<td data-th="Partner #1 Given Name">-</td>'; };
										if ( (record_partner1_surname !== '') && (record_partner1_surname !== null) && (record_partner1_surname !== undefined) ) { template += '<td data-th="Partner #1 Surname">' + record_partner1_surname.toUpperCase() + '</td>'; } else { template += '<td data-th="Partner #1 Surname">-</td>'; };
										if ( (record_partner1_suffix !== '') && (record_partner1_suffix !== null) && (record_partner1_suffix !== undefined) ) { template += '<td data-th="Partner #1 Suffix">' + record_partner1_suffix.toUpperCase() + '</td>'; } else { template += '<td data-th="Partner #1 Suffix">-</td>'; };
										if ( (record_partner2_givenname !== '') && (record_partner2_givenname !== null) && (record_partner2_givenname !== undefined) ) { template += '<td data-th="Partner #2 Given Name">' + record_partner2_givenname.toUpperCase() + '</td>'; } else { template += '<td data-th="Partner #2 Given Name">-</td>'; };
										if ( (record_partner2_surname !== '') && (record_partner2_surname !== null) && (record_partner2_surname !== undefined) ) { template += '<td data-th="Partner #2 Surname">' + record_partner2_surname.toUpperCase() + '</td>'; } else { template += '<td data-th="Partner #2 Surname">-</td>'; };
										if ( (record_partner2_suffix !== '') && (record_partner2_suffix !== null) && (record_partner2_suffix !== undefined) ) { template += '<td data-th="Partner #2 Suffix">' + record_partner2_suffix.toUpperCase() + '</td>'; } else { template += '<td data-th="Partner #2 Suffix">-</td>'; };
									}
									/* Use formatted date, not original date, as some are created weirdly */
									if ( (record_date_formatted !== '') && (record_date_formatted !== null) && (record_date_formatted !== undefined) ) { 
										var record_date_formatted_obj = '',
										record_date_month = '',
										record_date_day = '',
										record_date_year = '';
										/* need to format the date as a Date object first */ 
										var record_date_formatted_obj = new Date(record_date_formatted);
										// if ( (record_date_formatted_obj.isValid()) === true ) {
										if ( record_date_formatted_obj && (record_date_formatted_obj !== undefined) ) {
											var record_date_month = monthNamesShort[record_date_formatted_obj.getUTCMonth()].toUpperCase();
											var record_date_day = record_date_formatted_obj.getUTCDate();
											var record_date_year = record_date_formatted_obj.getUTCFullYear();
											// console.log('Original date: ' + record_date_formatted);
											// console.log('Formatted date: ' + record_date_formatted_obj);	
											if (record_date_year !== '2017') {	
												template += '<td data-th="Date of Record">' + record_date_month + ' ' + record_date_day + ', ' + record_date_year;
											} else {
												template += '<td data-th="Date of Record">' + record_date_year + '<br /><span class="note">(NOTE: available marriage data for 2017 usually does not include the actual month and day.)</span>';
											}
											template +='</td>'; 
										} else {
											template += '<td data-th="Date of Record">(possibly invalid date; originally listed as \'' + record_date_formatted + '\')</td>';
										}
									} else { 
										template += '<td data-th="Date of Record">-</td>';
									};
									if ( (record_location_town !== '') && (record_location_town !== null) && (record_location_town !== undefined) ) { template += '<td data-th="Town or City">' + record_location_town + '</td>'; } else { template += '<td data-th="Town or City">-</td>'; };
									if ( (record_location_county !== '') && (record_location_county !== null) && (record_location_county !== undefined) ) { template += '<td data-th="County">' + record_location_county + '</td>'; } else { template += '<td data-th="County">-</td>'; };
									// template += '<td data-th="Order Certificate"><button class="button is-small btn-align accent-btn raised rounded btn-outlined">Order Certificate</button></td>';
									template += '</tr>';
									$("#resultsHereMarriages").append(template);
								// }
							}
							// handle civil unions (note: these include state file number, unlike other records)
							if (record_type == 'CIVIL UNION') {
								// if (score > '1') {
									numberOfRelevantResultsCivilUnions = numberOfRelevantResultsCivilUnions + 1;
									var template = '<tr';
									if ( (record_id !== '') && (record_id !== null) && (record_id !== undefined) ) { template += ' id="row-' + record_id + '">'; } else { template += '>'; };
									if ( (record_partner1_givenname !== '') && (record_partner1_givenname !== null) && (record_partner1_givenname !== undefined) ) { template += '<td data-th="Partner #1 Given Name">' + record_partner1_givenname.toUpperCase() + '</td>'; } else { template += '<td data-th="Partner #1 Given Name">-</td>'; };
									if ( (record_partner1_surname !== '') && (record_partner1_surname !== null) && (record_partner1_surname !== undefined) ) { template += '<td data-th="Partner #1 Surname">' + record_partner1_surname.toUpperCase() + '</td>'; } else { template += '<td data-th="Partner #1 Surname">-</td>'; };
									if ( (record_partner1_suffix !== '') && (record_partner1_suffix !== null) && (record_partner1_suffix !== undefined) ) { template += '<td data-th="Partner #1 Suffix">' + record_partner1_suffix.toUpperCase() + '</td>'; } else { template += '<td data-th="Partner #1 Suffix">-</td>'; };
									if ( (record_partner2_givenname !== '') && (record_partner2_givenname !== null) && (record_partner2_givenname !== undefined) ) { template += '<td data-th="Partner #2 Given Name">' + record_partner2_givenname.toUpperCase() + '</td>'; } else { template += '<td data-th="Partner #2 Given Name">-</td>'; };
									if ( (record_partner2_surname !== '') && (record_partner2_surname !== null) && (record_partner2_surname !== undefined) ) { template += '<td data-th="Partner #2 Surname">' + record_partner2_surname.toUpperCase() + '</td>'; } else { template += '<td data-th="Partner #2 Surname">-</td>'; };
									if ( (record_partner2_suffix !== '') && (record_partner2_suffix !== null) && (record_partner2_suffix !== undefined) ) { template += '<td data-th="Partner #2 Suffix">' + record_partner2_suffix.toUpperCase() + '</td>'; } else { template += '<td data-th="Partner #2 Suffix">-</td>'; };
									/* Use formatted date, not original date, as some are created weirdly */
									if ( (record_date_formatted !== '') && (record_date_formatted !== null) && (record_date_formatted !== undefined) ) { 
										var record_date_formatted_obj = '',
										record_date_month = '',
										record_date_day = '',
										record_date_year = '';
										/* need to format the date as a Date object first */ 
										var record_date_formatted_obj = new Date(record_date_formatted);
										// if ( (record_date_formatted_obj.isValid()) === true ) {
										if ( record_date_formatted_obj && (record_date_formatted_obj !== undefined) ) {
											var record_date_month = monthNamesShort[record_date_formatted_obj.getUTCMonth()].toUpperCase();
											var record_date_day = record_date_formatted_obj.getUTCDate();
											var record_date_year = record_date_formatted_obj.getUTCFullYear();
											// console.log('Original date: ' + record_date_formatted);
											// console.log('Formatted date: ' + record_date_formatted_obj);		
											template += '<td data-th="Date of Record">' + record_date_month + ' ' + record_date_day + ', ' + record_date_year + '</td>'; 
										} else {
											template += '<td data-th="Date of Record">(possibly invalid date; originally listed as \'' + record_date_formatted + '\')</td>';
										}
									} else { 
										template += '<td data-th="Date of Record">-</td>';
									};
									if ( (record_location_town !== '') && (record_location_town !== null) && (record_location_town !== undefined) ) { template += '<td data-th="Town or City">' + record_location_town + '</td>'; } else { template += '<td data-th="Town or City">-</td>'; };
									if ( (record_location_county !== '') && (record_location_county !== null) && (record_location_county !== undefined) ) { template += '<td data-th="County">' + record_location_county + '</td>'; } else { template += '<td data-th="County">-</td>'; };
									if ( (record_state_file_number !== '') && (record_state_file_number !== null) && (record_state_file_number !== undefined) ) { template += '<td data-th="State File Number">' + record_state_file_number + '</td>'; } else { template += '<td data-th="State File Number">-</td>'; };
									// template += '<td data-th="Order Certificate"><button class="button is-small btn-align accent-btn raised rounded btn-outlined">Order Certificate</button></td>';
									template += '</tr>';
									$("#resultsHereCivilUnions").append(template);
								// }
							}
							// handle deaths
							if (record_type == 'DEATH') {
								// if (score > '1') {
									numberOfRelevantResultsDeaths = numberOfRelevantResultsDeaths + 1;
									var template = '<tr';
									if ( (record_id !== '') && (record_id !== null) && (record_id !== undefined) ) { template += ' id="row-' + record_id + '">'; } else { template += '>'; };
									if ( (record_givenname !== '') && (record_givenname !== null) && (record_givenname !== undefined) ) { template += '<td data-th="Given Name">' + record_givenname.toUpperCase() + '</td>'; } else { template += '<td data-th="Given Name">-</td>'; };
									if ( (record_surname !== '') && (record_surname !== null) && (record_surname !== undefined) ) { template += '<td data-th="Surname">' + record_surname.toUpperCase() + '</td>'; } else { template += '<td data-th="Surname">-</td>'; };
									if ( (record_suffix !== '') && (record_suffix !== null) && (record_suffix !== undefined) ) { template += '<td data-th="Suffix">' + record_suffix.toUpperCase() + '</td>'; } else { template += '<td data-th="Suffix">-</td>'; };
									/* Use formatted date, not original date, as some are created weirdly */
									if ( (record_date_formatted !== '') && (record_date_formatted !== null) && (record_date_formatted !== undefined) ) { 
										var record_date_formatted_obj = '',
										record_date_month = '',
										record_date_day = '',
										record_date_year = '';
										/* need to format the date as a Date object first */ 
										var record_date_formatted_obj = new Date(record_date_formatted);
										// if ( (record_date_formatted_obj.isValid()) === true ) {
										if ( record_date_formatted_obj && (record_date_formatted_obj !== undefined) ) {
											var record_date_month = monthNamesShort[record_date_formatted_obj.getUTCMonth()].toUpperCase();
											var record_date_day = record_date_formatted_obj.getUTCDate();
											var record_date_year = record_date_formatted_obj.getUTCFullYear();
											// console.log('Original date: ' + record_date_formatted);
											// console.log('Formatted date: ' + record_date_formatted_obj);		
											template += '<td data-th="Date of Record">' + record_date_month + ' ' + record_date_day + ', ' + record_date_year + '</td>'; 
										} else {
											template += '<td data-th="Date of Record">(invalid date; originally listed as ' + record_date_formatted + ')</td>';
										}
									} else { 
										template += '<td data-th="Date of Record">-</td>';
									};
									if ( (record_location_town !== '') && (record_location_town !== null) && (record_location_town !== undefined) ) { template += '<td data-th="Town or City">' + record_location_town + '</td>'; } else { template += '<td data-th="Town or City">-</td>'; };
									if ( (record_location_county !== '') && (record_location_county !== null) && (record_location_county !== undefined) ) { template += '<td data-th="County">' + record_location_county + '</td>'; } else { template += '<td data-th="County">-</td>'; };
									// template += '<td data-th="Order Certificate"><button class="button is-small btn-align accent-btn raised rounded btn-outlined">Order Certificate</button></td>';
									template += '</tr>';
									$("#resultsHereDeaths").append(template);
								// }
							}
						}
					}

					// handle error messages, if needed
					if (numberOfRelevantResultsBirths == 0) {
						var template = '<tr><td colspan="6" class="no-close-results">There were no high-ranking results for this search. Please try again.</td></tr>';
						$("#resultsHereBirths").append(template);
					}
					if (numberOfRelevantResultsMarriages == 0) {
						var template = '<tr><td colspan="9" class="no-close-results">There were no high-ranking results for this search. Please try again.</td></tr>';
						$("#resultsHereMarriages").append(template);
					}
					if (numberOfRelevantResultsCivilUnions == 0) {
						var template = '<tr><td colspan="10" class="no-close-results">There were no high-ranking results for this search. Please try again.</td></tr>';
						$("#resultsHereCivilUnions").append(template);
					}
					if (numberOfRelevantResultsDeaths == 0) {
						var template = '<tr><td colspan="6" class="no-close-results">There were no high-ranking results for this search. Please try again.</td></tr>';
						$("#resultsHereDeaths").append(template);
					}

					// add total numbers of relevant results to the three main record type tabs
					if (numberOfRelevantResultsBirths !== 0) {
						var template = ' (' + numberOfRelevantResultsBirths + ')';
						$("#totalBirths").append(template);
					}
					if (numberOfRelevantResultsMarriages !== 0) {
						var template = ' (' + numberOfRelevantResultsMarriages + ')';
						$("#totalMarriages").append(template);
					}
					if (numberOfRelevantResultsCivilUnions !== 0) {
						var template = ' (' + numberOfRelevantResultsCivilUnions + ')';
						$("#totalCivilUnions").append(template);
					}
					if (numberOfRelevantResultsDeaths !== 0) {
						var template = ' (' + numberOfRelevantResultsDeaths + ')';
						$("#totalDeaths").append(template);
					}

				}

				// now that we have the results, we can remove the loading spinner
				$("#results").removeClass('loading');

				// destroy any existing tablesorter, if there is one
				$.tablesorter.destroy('#results #showBirthResults table', true);
				$.tablesorter.destroy('#results #showMarriageResults table', true);
				$.tablesorter.destroy('#results #showCivilUnionResults table', true);
				$.tablesorter.destroy('#results #showDeathResults table', true);
				// add table sorter, but explicitly do it four separate times
				$("#results #showBirthResults table").tablesorter({
					widgets : ['zebra', 'columns'],
					theme: 'grey',
					showProcessing: true,
					ignoreCase: true,
					cssIcon: 'tablesorter-icon',
					initialized : function(table){
						$(table).find('thead .tablesorter-header-inner').append('<i class="tablesorter-icon"></i>');
					}
				});
				$("#results #showMarriageResults table").tablesorter({
					widgets : ['zebra', 'columns'],
					theme: 'grey',
					showProcessing: true,
					ignoreCase: true,
					cssIcon: 'tablesorter-icon',
					initialized : function(table){
						$(table).find('thead .tablesorter-header-inner').append('<i class="tablesorter-icon"></i>');
					}
				});
				$("#results #showCivilUnionResults table").tablesorter({
					widgets : ['zebra', 'columns'],
					theme: 'grey',
					showProcessing: true,
					ignoreCase: true,
					cssIcon: 'tablesorter-icon',
					initialized : function(table){
						$(table).find('thead .tablesorter-header-inner').append('<i class="tablesorter-icon"></i>');
					}
				});
				$("#results #showDeathResults table").tablesorter({
					widgets : ['zebra', 'columns'],
					theme: 'grey',
					showProcessing: true,
					ignoreCase: true,
					cssIcon: 'tablesorter-icon',
					initialized : function(table){
						$(table).find('thead .tablesorter-header-inner').append('<i class="tablesorter-icon"></i>');
					}
				});
				// submit search event to Google Analytics
				/*
				ga('send', {
					hitType: 'event',
					eventCategory: 'Search',
					eventAction: 'Search',
					eventLabel: 'CT Genealogy Index'
				});
				*/
			});
		}
	})
}


function openTab(evt, tabName) {
	var i, x, tablinks;
	x = document.getElementsByClassName("content-tab");
	for (i = 0; i < x.length; i++) {
		x[i].style.display = "none";
	}
	tablinks = document.getElementsByClassName("tab");
	for (i = 0; i < x.length; i++) {
		tablinks[i].className = tablinks[i].className.replace(" is-active", "");
	}
	document.getElementById(tabName).style.display = "block";
	evt.currentTarget.className += " is-active";
	// call the widgets of the tablesorter, to make the zebra striping work now the tab is visible
	$('#results #showBirthResults table').trigger('applyWidgets');
	$('#results #showMarriageResults table').trigger('applyWidgets');
	$('#results #showCivilUnionResults table').trigger('applyWidgets');
	$('#results #showDeathResults table').trigger('applyWidgets');
}




function doHighchartsMap(thequery, subtitleStuff) {
	var urlForFacets = thequery;
	var subtitleStuff = subtitleStuff;
	Highcharts.setOptions({
		lang: {
			thousandsSep: ','
		}
	});
	$.getJSON(urlForFacets, function(data) {
    	// get the data - but have to use the name in quotation marks because it has a comma in it
        var dataFacets = data.facet_counts.facet_pivot["record_location_town,record_location_latlon_text"];
        var dataFacetsCleaned = [];
        Object.keys(dataFacets).forEach(function(k){
			var townName = dataFacets[k]["value"];
			if ( (townName !== '') && (townName !== '.') && (townName !== '[missing or unknown]') ) {
				var townNameSentenceCase = townName.split(' ').map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase()).join(' ');
				// to account for a few small named towns with no latlon info
				if ( dataFacets[k]["pivot"] ) {
					var latlon = dataFacets[k]["pivot"][0].value;
					// have to split the latlon into two parts
					var arrayLatLon = latlon.split(",");
					var lat = parseFloat(arrayLatLon[0]);
					var lon = parseFloat(arrayLatLon[1]);
					var count = dataFacets[k]["pivot"][0].count;
					let myJson = {"name": townName , "name_s": townNameSentenceCase, "latlon": latlon, "lat": lat, "lon": lon, "z": count };
					dataFacetsCleaned.push(myJson);
				}
			}
		});
		Highcharts.mapChart('containerMap', {
			chart: {
				// the map referenced in the line below needs to be explicitly added to the gulpfile
				map: Highcharts.maps['countries/us/us-ct-all'],
				borderWidth: 1,
		        // color: '#c4392d',
		        spacingTop: 30,
		        spacingRight: 30,
		        spacingBottom: 20,
		        spacingLeft: 20,
		        marginTop: 80,
		        marginRight: 40, // to give more room on the right side of the map for the legend
			},
			title: {
				text: 'Connecticut Genealogy Index - Search Results, Mapped'
			},
			subtitle: {
		    	text: subtitleStuff // set by javascript
		    },
			legend: {
				title: {
					text: 'Records',
					style: {
						color: (
							Highcharts.defaultOptions &&
							Highcharts.defaultOptions.legend &&
							Highcharts.defaultOptions.legend.title &&
							Highcharts.defaultOptions.legend.title.style &&
							Highcharts.defaultOptions.legend.title.style.color
						) || 'black'
					}
				},
				align: 'right',
				verticalAlign: 'bottom',
				floating: true,
				layout: 'vertical',
				valueDecimals: 0,
				backgroundColor: (
					Highcharts.defaultOptions &&
					Highcharts.defaultOptions.legend &&
					Highcharts.defaultOptions.legend.backgroundColor
				) || 'rgba(255, 255, 255, 0.85)',
				symbolRadius: 0,
				symbolHeight: 14
			},
			mapNavigation: {
		        enabled: true,
		        buttonOptions: {
		        	align: 'right',
		            verticalAlign: 'top'
		        }
		    },
		    // can add more than one colorAxis if needed, and then reference each from the data sets
			colorAxis: [
				{
					dataClasses: [
						{ to: 10 },
						{ from: 10, to: 100 }, 
						{ from: 100, to: 1000 }, 
						{ from: 1000, to: 10000 },
						{ from: 10000 }
					],
					min: 1, // cannot be zero if type is logarithmic
		            type: 'linear', // linear or logarithmic
		            minColor: '#CCCCEE',
		            maxColor: '#000099',
				}
			],
			series: [
				{
			        // Map with no data as a basemap
			        name: 'Basemap',
			        borderColor: '#606060',
	    			// nullColor: 'rgba(200, 200, 200, 0.2)',
	    			showInLegend: false,
	    			enableMouseTracking: false
			    },
			    {
			    	// Map separators
			        name: 'Separators',
			        type: 'mapline',
			        color: '#101010',
			        // nullColor: '#707070',
			        showInLegend: false,
			        enableMouseTracking: false
			    },
				{
			    	// Map using latitude and longitude points
			    	name: 'Results by Towns and Cities',
			    	type: 'mapbubble',	
					data: dataFacetsCleaned,
					colorAxis: 0,
					maxSize: '10%',
					tooltip: {
						headerFormat: '',
						pointFormat: '<strong>{point.name}:</strong><br />{point.z} close matches'
					},
				}
			]
		});
	});
}



function doHighchartsGraph(thequeryForChart, subtitleStuff) {
	var urlForChart = thequeryForChart;
	var subtitleStuff = subtitleStuff;
	$.getJSON(urlForChart, function(data) {
        var recordTypes = data.facet_counts.facet_pivot.record_type;
        for (let i=0; i<recordTypes.length; i++) {
        	var dateData = recordTypes[i].ranges.record_date_formatted.counts
	        var dateDataCleaned = [];
			dateData.toString().replace(/([^\,]+)\,([^\,]+)/g, function($0, $1, $2){
				dateDataCleaned[$1] = $2;
			});
			var dateDataCleaned2 = [];
			for (var date in dateDataCleaned) {
				dateDataCleaned2.push([Date.parse(date), parseFloat(dateDataCleaned[date])]);
			}
			var whatKindOfData = recordTypes[i].value;
			if (whatKindOfData == 'BIRTH') {
				var dataBirths = dateDataCleaned2;
			} else if (whatKindOfData == 'CIVIL UNION') {
				var dataCivilUnions = dateDataCleaned2;
			} else if (whatKindOfData == 'DEATH') {
				var dataDeaths = dateDataCleaned2;
			} else if (whatKindOfData == 'MARRIAGE') {
				var dataMarriages = dateDataCleaned2;
			}
        }
        doHighchartsGraphNow(dataBirths, dataCivilUnions, dataDeaths, dataMarriages, subtitleStuff);
    });
}
function doHighchartsGraphNow(dataBirths, dataCivilUnions, dataDeaths, dataMarriages, subtitleStuff) {
	var dataBirths = dataBirths;
	var dataCivilUnions = dataCivilUnions;
	var dataDeaths = dataDeaths;
	var dataMarriages = dataMarriages;
	var subtitleStuff = subtitleStuff;
	Highcharts.setOptions({
		lang: {
			thousandsSep: ','
		}
	});
	var optionsForGraph = {
		chart: {
	        type: 'areaspline',
	        color: '#c4392d',
	        spacingTop: 30,
	        spacingRight: 30,
	        spacingBottom: 20,
	        spacingLeft: 20,
	        marginTop: 100
	    },
	    title: {
	        text: 'Connecticut Genealogy Index - Search Results, Graphed'
	    },
	    subtitle: {
	    	text: subtitleStuff // set by javascript
	    },
	    xAxis: {
	    	title: {
	            text: 'Record Date'
	        },
	    	type: 'datetime',
	    	dateTimeLabelFormats: {
	    		day: '%d %b %Y',
	            month: '%b %Y',
	            year: '%Y'
	        },
	    	tickPixelInterval: 80,
	    	showLastLabel: true,
	    	accessibility: {
	    		enabled: true
	        },
	    },
	    yAxis: {
	        title: {
	            text: 'Number of matching records'
	        },
	        accessibility: {
	    		enabled: true
	        }
	    },
	    tooltip: {
        	formatter: function () {
        		return ['<strong>' + Highcharts.dateFormat("%Y", this.x) + '</strong>'].concat(
	                this.points ? this.points.map(function (point) {
                    	if (point.y === 0) {
							return false;
						} else if (point.y === 1) {
                        	return point.series.name + ': ' + Highcharts.numberFormat(point.y,0) + ' close match';
						} else {
                        	return point.series.name + ': ' + Highcharts.numberFormat(point.y,0) + ' close matches';
                        }
                    }) : []
	            );
	        },
	        split: true
	    },
	    accessibility: {
	    	enabled: true,
	    	announceNewData: {
	    		enabled: true
	    	}
	    },
	    plotOptions: {
	    	accessibility: {
	    		enabled: true
	    	},
	        areaspline: {
	            fillOpacity: 0.5
	        },
	        dataLabels: {
                enabled: true
            },
            series: {
	            animation: {
	            	defer: 700,
	                duration: 3000
	            }
	        },
	        area: {
				fillColor: {
					linearGradient: {
						x1: 0,
						y1: 0,
						x2: 0,
						y2: 1
					},
					stops: [
						[0, Highcharts.getOptions().colors[0]],
              			[1, Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
					]
				},
				marker: {
					radius: 2
				},
				lineWidth: 1,
				states: {
					hover: {
						lineWidth: 1
					}
				},
				threshold: null
            },
	    },
	    series: [
		    {
		    	name: 'Births',
		    	type: 'area',
		    	color: '#9999CC',
		    	data: dataBirths
		    },
		    {
		    	name: 'Marriages',
		    	type: 'area',
		    	color: '#000099',
		    	data: dataMarriages
		    },
		    {
		    	name: 'Civil Unions',
		    	type: 'area',
		    	color: '#3333CC',
		    	data: dataCivilUnions
		    },
		    {
		    	name: 'Deaths',
		    	type: 'area',
		    	color: '#000000',
		    	data: dataDeaths
		    }
	    ],
	};
	Highcharts.chart('containerGraph', optionsForGraph);
}

